import React from "react";
import Header from './Header/MainHeader'
import Footer from './Footer'
import Body from './Body'


const FormPage = () => {
    return (
      <div>
     <Header/>
     <Body/>
     <Footer/>
     </div>
);
};

export default FormPage;