import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import './Css/Footer.css'
const FooterPagePro = () => {
  return (
    <MDBFooter  className="font-small pt-0 bg-gradient-footer">
      <MDBContainer>
        <MDBRow className="pt-5 mb-3 text-center d-flex justify-content-center font-body" id="bgfooter">
          <MDBCol md="2" className="b-3">
            <h6 className="title font-weight-bold">
              <a href="/features">Feature</a>
            </h6>
          </MDBCol>
          <MDBCol md="2" className="b-3">
            <h6 className="title font-weight-bold">
              <a rel="noopener noreferrer" href="https://www.instibuddy.in" target="_blank">Instibuddy</a>
            </h6>
          </MDBCol>
          <MDBCol md="2" className="b-3">
            <h6 className="title font-weight-bold">
              <a href="#!">Upcomings</a>
            </h6>
          </MDBCol>
          <MDBCol md="2" className="b-3">
            <h6 className="title font-weight-bold">
              <a href="#!">Help</a>
            </h6>
          </MDBCol>
          <MDBCol md="2" className="b-3">
            <h6 className="title font-weight-bold">
              <a href="#!">Contact</a>
            </h6>
          </MDBCol>
        </MDBRow>
        <hr className="rgba-white-light" style={{ margin: "0 15%" }} />
        <MDBRow className="d-flex text-center justify-content-center mb-md-0 mb-4">
          <MDBCol md="8" sm="12" className="mt-5 font-body">
            <p style={{ lineHeight: "1.7rem" }}>
              instiBuddy is a joint initiative taken by IIT Bombay students, professor and alumni that brings in well-experienced Mentors, who are students from different IITs, NITs, and other engineering colleges. You can have a face to face talk with the mentors and discuss your college-related doubts with them freely. We make sure that you get all of the information you need before you make the 'life-changing decision'.
            </p>
          </MDBCol>
        </MDBRow>
        <hr className="clearfix d-md-none rgba-white-light" style={{ margin: "10% 15% 5%" }} />
        <MDBRow className="pb-3">
          <MDBCol md="12">
            <div className="mb-5 flex-center">
              
              <a className="tw-ic" rel="noopener noreferrer" href="https://twitter.com/instibuddyapp/" target="_blank">
                <i className="fab fa-twitter fa-lg white-text mr-md-4"> </i>
              </a>
              
              <a className="li-ic" rel="noopener noreferrer" href="https://www.linkedin.com/company/instibuddyapp" target="_blank">
                <i className="fab fa-linkedin-in fa-lg white-text mr-md-4"> </i>
              </a>
              <a className="ins-ic" rel="noopener noreferrer" href="https://www.instagram.com/instibuddyapp/" target="_blank">
                <i className="fab fa-instagram fa-lg white-text mr-md-4"> </i>
              </a>
              
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright:
          <a href="https://www.instibuddy.in"> instiBuddy.in </a>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
}

export default FooterPagePro;